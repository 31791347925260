<template>
  <div>
    <el-select
      :value="value"
      @change="onChange"
      filterable
      v-loading="loading"
    >
      <el-option
        v-for="account in allAccount"
        :label="account.accountName"
        :value="account.accountId"
        :key="account.accountId"
      ></el-option>
    </el-select>
    <el-button
      type="text"
      icon="el-icon-refresh"
      @click="getCacheAccount"
    ></el-button>
    <span class="tips">{{ credentialName }}</span>
  </div>
</template>
<script>
import { getAllAccount, cacheAndGetAccount } from '@/api/multiPlatform';
export default {
  props: {
    value: String,
    platform: String,
  },
  data() {
    return {
      allAccount: [],
      loading: false,
    };
  },
  computed: {
    credentialName() {
      if (!this.value) return;
      const accountInfo = this.allAccount.find((item) => item.accountId == this.value) || {};
      return accountInfo.credentialName;
    },
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
    getAllAccount() {
      let params = {
        platform: this.platform,
      };
      this.loading = true;
      getAllAccount(params)
        .then((res) => {
          this.allAccount = res.data;
          this.$emit('getAllList', res.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCacheAccount() {
      let params = {
        platform: this.platform,
      };
      this.loading = true;
      cacheAndGetAccount(params)
        .then((res) => {
          this.allAccount = res.data;
          this.$emit('getAllList', res.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    platform: {
      immediate: true,
      handler() {
        if (this.platform) {
          this.getAllAccount();
        }
      },
    },
  },
};
</script>
<style scoped>
.tips {
  color: #999;
  font-size: 12px;
  margin-left: 10px;
}
</style>
