import request from '@/utils/request';
import { getHeader } from '@/utils/uploadMd5';

export function uploadSingle(file, config) {
  let header = getHeader('/api/media/upload/single');
  let data = new FormData();
  data.append('file', file.raw);
  return request({
    baseURL: process.env.VUE_APP_MEDIA,
    url: '/upload/single',
    method: 'post',
    data,
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...header,
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
    onUploadProgress: (progressEvent) => {
      progressEvent.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      config.onUploadProgress(progressEvent, file);
    },
  });
}

export function uploadImg(file) {
  console.log(file);
  let header = getHeader('/api/media/upload/single');
  let data = new FormData();
  data.append('file', file.file);
  return request({
    baseURL: process.env.VUE_APP_MEDIA,
    url: '/upload/single',
    method: 'post',
    data,
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...header,
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
